<template>

  <referentiel-list
    :listTitle="'Liste des zone Vulnérable'"
    :referentielName="referentielName"
    :tableFields="tableFields"
  >
  
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <echelon> </echelon>
        <div class="col-sm-12">
        
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select
                id="Village"
                name="village"
                placeholder="village"
                option-label="Village"
                option-value="id"
                v-model="editableItem.village"
                :options="villages"
                class="form-select"
              >
              </c-select>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <c-select
                id="vulnerabilite"
                name="vulnerabilite"
                placeholder="Niveau de vulnérabiloté"
                option-label="Niveau de vulnérabiloté"
                option-value="id"
                v-model="editableItem.niveauVulnerabilite"
                :options="niveauVulnerabilite"
                class="form-select"
              >
              </c-select>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
              <label for="vulnerabilite">Niveau de vulnérabiloté</label>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.score"
                @input="handleInput"
                type="text"
                class="form-control"
                id="score"
                name="score"
                placeholder="Score"
              />
              <label for="name">score</label>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import Echelon from "../../../components/common/echelon/Echelon.vue";
import { mapGetters,mapActions } from "vuex";
export default {
  components: {
    ReferentielList,
    Echelon
  },
  computed: {
    ...mapGetters({
        categories: 'categorie/categories',
    }),
    referentielName() {
      return "zone vulnérable";
    },
    emptyReferentielItem () {
      return {
        libelle: null,
        score: null,
        categorie : null
      }
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "libelle", label: "Libellé" },
        { key: "code", label: "Code" },
        "actions",
      ];
    },
   
  },
  created() {
      this.fetchCategories()
  },
  methods: {
    ...mapActions({
      fetchCategories: "categorie/fetchCategories",
    }),
  },
};
</script>

<style></style>
